import React, { useEffect, useState } from 'react';
import './CustomChart.scss'
import { useTranslation } from 'react-i18next';
import { useAPI } from '../../../shared/services/api/API';
import { OPTIVAL_CONSTANTS } from '../../../shared/Constants';
import { Typography } from '@mui/material';
import { SharedApi } from '../../../shared/services/shared-api/SharedApi';

export const CustomChart = () => {
    const { t } = useTranslation();
    const { getColorForLevel } = SharedApi();
    const [topSkills,setTopSkills] = useState<any>([]);
    const [skills,setSkills] = useState<any>([]);
    const [skillLevels,setSkillLevels] = useState<any>([]);

    const { httpGet, handleAlertBar } = useAPI();
    const fetchTopSkillsWithCount = () => {
        const lang = sessionStorage.getItem(
          OPTIVAL_CONSTANTS.EMPLOYEE_PREFERRED_LANGUAGE
        );
        const queryParam = {
            count: 6,
            language: lang
        }
        httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_WITH_COUNT, queryParam).then(
            (response) => {
              if (response && response.data) {
                if (response.data.error) {
                  handleAlertBar("error", response.data.message);
                } else {
                    setSkillLevels(response?.data?.skillLevels);
                    setSkills(response?.data?.skills);
                    setTopSkills(response.data?.topSkills);
                }
              } else {
                handleAlertBar("error", t("opva.serviceUnavailable"));
              }
            }
          );
    }

    const calculateProgress = (count: number) => {
        const maxCount = 10;
        return (count/maxCount) * 100;
    }

    useEffect(() => {
        fetchTopSkillsWithCount(); 
    },[])
  return (
    <div className='custom-chart-container p-4'>
        <div>
            <span>{t("opva.topSkills")}</span>
        </div>
        <table className='mt-3' style={{width: "100%"}}>
            <tbody className='mt-3'>
                {topSkills?.map((topSkill: any) => {
                    const skill = skills?.find((s: any) => s?.id === topSkill?.skillId);
                    return (
                        <tr key={skill?.id} style={{border: "none", marginTop: "20px", marginBottom: "20px", height: "40px"}}>
                            <td style={{textAlign: "left", marginBottom: "12px", marginTop: "12px"}}>
                            <div
                              style={{ width: "110px"}}
                            >
                              <Typography style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} title={skill?.name ? skill?.name : "-"}>{skill?.name ? skill?.name : "-"}</Typography>
                            </div>
                            </td>
                            {skillLevels?.map((level: any)=>{
                                const levelData = topSkill?.levels?.find((l: any) => l?.id === level?.id);
                                const progress = levelData ? calculateProgress(levelData?.currentCount) : 0;

                                return (
                                    <td key={level?.id} style={{textAlign: "center"}}>
                                        <span style={{
                                            backgroundColor: getColorForLevel(
                                                levelData?.id
                                            ),
                                            borderRadius:"4px",
                                            fontSize:"12px"
                                            }} className="pt-1 pb-1 px-2">{levelData?.currentCount}</span>
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>

        </table>

    </div>
  )
}