import {
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    Table,
    TableBody,
    Link,
    TablePagination,
    TableSortLabel,
    Box,
  } from "@mui/material";
  import AssignmentReturnOutlinedIcon from "@mui/icons-material/AssignmentReturnOutlined";
  import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
  import LowPriorityIcon from "@mui/icons-material/LowPriority";
  import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
  import { visuallyHidden } from "@mui/utils";
  import { useEffect, useState, KeyboardEvent, useMemo } from "react";
  import { Navigate, NavLink, useNavigate } from "react-router-dom";
  import { useTranslation } from "react-i18next";
import { SkillsData } from "../../models/SkillsData";
import { TableLoading } from "../../../../shared/components/skeleton/table-loading/TableLoading";
import { SkillsConstants } from "../../models/SkillsConstants";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";
import { useAPI } from "../../../../shared/services/api/API";
import { useSkillsUtils } from "../skills/SkillsUtil";
import { Order, TableHeadCell } from "../../../../shared/models/TableModels";
import { tableSorting, updateURLParams } from "../../../../shared/utils/Utils";

export const SkillsManagement = (Props: any) => {
    let queryParams = {
      page: 0,
      size: 1000,
      sortColumn: "modifiedAt",
      isDescending: true,
      searchString: Props.searchText,
      languageId: 1 || ''
    };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [skillData, setSkillsData] = useState<SkillsData[]>([]);
    const [displaySkillsData, setDisplaySkillsData] = useState<SkillsData[]>([]);
    const [editSkillData, setEditSkillData] = useState<SkillsData>();
    const { httpGet, httpPost, httpPut, handleAlertBar, httpDelete, checkRole } =
      useAPI();
    const [openDialog, setOpenDialog] = useState(false);
    const [skillObj, setSkillObj] = useState({
      skillName: "",
      skillDescription: "",
    });
    const [isLoading, setIsLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [mode, setMode] = useState("");
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<string>("name");
    const [skillDataVisible, setskillDataVisible] = useState<SkillsData[]>([]);
    const [skillId, setSkillId] = useState<number>(0);
    const [isAlertDialog, setIsAlertDialog] = useState(false);
    const { getSkillzHeaderCell } = useSkillsUtils();
    const [skillzHeaderCell, setSkillzHeaderCell] = useState<TableHeadCell[]>([]);
  
    const handleAlertDialogOpen = (groupId: number) => {
      setSkillId(groupId);
      setIsAlertDialog(true);
    };

    useEffect(() => {
        setSkillzHeaderCell(getSkillzHeaderCell());
    }, [skillData]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
      ) => {
        setPage(newPage);
        const startIndex = newPage * rowsPerPage;
        setDisplaySkillsData([
          ...skillData.slice(startIndex, startIndex + rowsPerPage),
        ]);
      };
    
      const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setDisplaySkillsData(skillData.slice(0, parseInt(event.target.value, 10)));
      };
  
    const handleAlertDialogClose = () => {
      setSkillId(0);
      setIsAlertDialog(false);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };
  
    const handleEditSkillsClick = async(data: SkillsData) => {
      const urlParams = {
        skillId: data?.id,
      };
      httpGet(
        updateURLParams(OPTIVAL_CONSTANTS.API_URLS.GET_EDIT_SKILLS, urlParams)
      ).then(
        (response) => {
          if (response && response.data) {
            if (response.data.error) {
              handleAlertBar(
                "error",
                response?.data?.message || t("opva.serviceUnavailable")
              );
              setIsLoading(false);
            } else {
              navigate(
                "/edit-skill?skillId=" + data.id,
                {
                  state: { skillData: response.data },
                }
              );
            }
          } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    };

    const handleSorting = (property: string) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  
    const handleEmptyPage = (data: any) => {
      const sliceArr = data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      if (!sliceArr.length) {
        setPage(page === 0 ? 0 : page - 1);
      }
    };
  
    const fetchSkillsData = async () => {
      setIsLoading(true);
      await httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS, queryParams).then(
        (response) => {
          if (response && response.data) {
            if (response.data.error) {
              handleAlertBar(
                "error",
                response?.data?.message || t("opva.serviceUnavailable")
              );
              setIsLoading(false);
            } else {
              setSkillsData(response.data);
              setDisplaySkillsData(response.data.slice(0, rowsPerPage));
              setIsLoading(false);
              handleEmptyPage(response.data);
            }
          } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    };
  
    const extractDate = (dateString: string) => {
      const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
      return formattedDate;
    };
  
    const handleDeleteSkills = () => {
      const urlParams = {
        skillId: skillId,
      };
      httpDelete(
        updateURLParams(OPTIVAL_CONSTANTS.API_URLS.DELETE_SKILLS, urlParams)
      ).then(
        (response) => {
          if (response && response.data && response.data.error) {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          } else {
            handleAlertBar("success", t("opva.skillDeleteMsg"));
            fetchSkillsData();
            handleAlertDialogClose();
          }
        },
        (error) => {}
      );
    };
    const toggleDescriptionText = (skillData: SkillsData) => {
      skillData.isDescriptionViewMore = !skillData.isDescriptionViewMore;
      setskillDataVisible([...skillDataVisible]);
    };
  
    const renderTruncateDescription = (skillData: SkillsData) => {
      return !skillData.isDescriptionViewMore ? (
        <>
          {skillData.description.substring(
            0,
            SkillsConstants.SKILL_DESCRIPTION_LENGTH
          )}{" "}
          <Link
            component="button"
            onClick={() => toggleDescriptionText(skillData)}
          >
            {t("opva.viewMore")}
          </Link>
        </>
      ) : (
        <>
          {skillData.description}
          <Link
            component="button"
            onClick={() => toggleDescriptionText(skillData)}
          >
            {t("opva.viewLess")}
          </Link>
        </>
      );
    };

    useMemo(() => {
        const propertyType = orderBy === "usersAssigned" ? "number" : "string";
        const sortedList = tableSorting(skillData, order, orderBy, propertyType);
        setSkillsData(sortedList);
        setDisplaySkillsData(
          sortedList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        );
      }, [order, orderBy, page, skillData]);
  
    useEffect(() => {
      const languageId: any = sessionStorage.getItem("languageId");
      queryParams.languageId = languageId;
      let timeOutId: number | undefined = undefined;
      if (Props.searchText.length >= 3) {
        timeOutId = window.setTimeout(() => {
          fetchSkillsData();
        }, 1000);
      } else if (Props.searchText.length === 0) {
        timeOutId = window.setTimeout(() => {
          queryParams.searchString = "";
          fetchSkillsData();
        }, 1000);
      }
      return () => {
        window.clearTimeout(timeOutId);
      };
    }, [Props.searchText]);

    useEffect(() => {
      if (Props.openDialog) {
        setOpenDialog(Props.openDialog);
      }
      if (Props.skillObj) {
        setSkillObj(Props.skillObj);
      }
      if (Props.mode) {
        setMode(Props.mode);
      }

    }, [Props.openDialog, Props.skillObj, Props.mode ]);
  
  
    return (
      <div>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TableContainer className="table-container">
            <Table stickyHeader size="medium" aria-label="Skill table">
              <TableHead>
                <TableRow key="skill-table-header">
                  {skillzHeaderCell.map((headCell: TableHeadCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={
                        orderBy === headCell.sortingProperty ? order : false
                      }
                      align={headCell.position}
                      sx={{
                        width: headCell.width + "%",
                      }}
                    >
                      {headCell.sorting ? (
                        <TableSortLabel
                          active={orderBy === headCell.sortingProperty}
                          direction={
                            orderBy === headCell.sortingProperty ? order : "asc"
                          }
                          onClick={() => handleSorting(headCell.sortingProperty)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {orderBy === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {isLoading ? (
                <TableLoading column={skillzHeaderCell.length} />
              ) : (
                <TableBody>
                  {displaySkillsData && displaySkillsData.length ? (
                    displaySkillsData.map((data: SkillsData) => (
                      <TableRow key={data.id}>
                        <TableCell
                          align="left"
                          style={{ wordBreak: "break-word" }}
                        >
                          {data.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ wordBreak: "break-word" }}
                        >
                          {data.description?.length >
                          SkillsConstants.SKILL_DESCRIPTION_LENGTH
                            ? renderTruncateDescription(data)
                            : data.description}
                        </TableCell>
                        <TableCell align="left">{data.createdBy}</TableCell>
                        {/* <TableCell className="p-2" align="left">
                          {extractDate(data.createdAt)}
                        </TableCell> */}
                        <TableCell align="center">{data.usersAssigned}</TableCell>
                        <TableCell align="center">{data?.validatedCount || '-'}</TableCell>
                        {checkRole([
                          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          OPTIVAL_CONSTANTS.SUPER_ADMIN,
                          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                          OPTIVAL_CONSTANTS.CONTENT_EDITOR,
                        ]) && (
                          <TableCell align="center">
                            <NavLink
                              to={"/map-workflow"}
                              state={{ skillId: data.id, skillName: data.name }}
                            >
                              <LowPriorityIcon color="primary" />
                            </NavLink>
                          </TableCell>
                        )}
                        {checkRole([
                          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          OPTIVAL_CONSTANTS.SUPER_ADMIN,
                          OPTIVAL_CONSTANTS.SUPERVISOR,
                        ]) && (
                          <TableCell align="center">
                            <NavLink
                              to={"/assign-skill"}
                              state={{ skillId: data.id, skillName: data.name }}
                            >
                              <AssignmentReturnOutlinedIcon color="primary" />
                            </NavLink>
                          </TableCell>
                        )}
                        {checkRole([
                          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          OPTIVAL_CONSTANTS.SUPER_ADMIN,
                          OPTIVAL_CONSTANTS.SUPERVISOR,
                          OPTIVAL_CONSTANTS.CONTENT_ADMIN,
                        ]) && (
                          <>
                            <TableCell align="center">
                              <EditOutlinedIcon
                                sx={{ cursor: "pointer" }}
                                color="primary"
                                onClick={() => {
                                  handleEditSkillsClick(data);
                                }}
                              />
                            </TableCell>
                          </>
                        )}
                        {checkRole([
                          OPTIVAL_CONSTANTS.ORGANIZATION_ADMIN,
                          OPTIVAL_CONSTANTS.OPERATIONS_ADMIN,
                          OPTIVAL_CONSTANTS.SUPER_ADMIN,
                        ]) && (
                          <>
                            <TableCell align="center">
                              <DeleteOutlineIcon
                               sx={{ cursor: "pointer" }}
                                color="error"
                                onClick={() => handleAlertDialogOpen(data.id)}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">
                        {Props.searchText
                          ? t("opva.noSearchSkillDataAvailable")
                          : t("opva.noDataAvailable")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
            <div className="d-flex align-items-center justify-content-end">
              <TablePagination
                className="pagination-container mb-4"
                component="div"
                count={skillData?.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </TableContainer>
        </Box>
  
        <AlertDialog
          open={isAlertDialog}
          title={t("opva.deleteSkil")}
          description={t("opva.wantToDeleteSkill")}
          submitBtnText={t("opva.delete")}
          handleSubmit={handleDeleteSkills}
          handleClose={handleAlertDialogClose}
        ></AlertDialog>
      </div>
    );
}